import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
export const API_URL2 = 'https://hrms.hivelab.co.kr';

export const FETCH_DATA_BEGIN = 'FETCH_DATA_BEGIN';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';
export const LOAD_PROJECTS_ERROR = 'LOAD_PROJECTS_ERROR';
export const SET_DEPT_CODES = 'SET_DEPT_CODES';

// 수정: fetchDataBegin, fetchDataSuccess, fetchDataFailure 액션 생성자 함수 업데이트
export const fetchDataBegin = (target) => ({
    type: FETCH_DATA_BEGIN,
    payload: { target },
});

export const fetchDataSuccess = (data, target) => ({
    type: FETCH_DATA_SUCCESS,
    payload: { data, target },
});

export const fetchDataFailure = (error, target) => ({
    type: FETCH_DATA_FAILURE,
    payload: { error, target },
});

export const setDeptCodes = (deptCodes) => {
    return {
        type: SET_DEPT_CODES,
        payload: deptCodes,
    };
};

// 두 객체를 합치는 함수
const mergeProjects = (obj1, obj2) => {
    const merged = { ...obj1 };

    for (const [key, value] of Object.entries(obj2)) {
        if (merged[key]) {
            // 이미 키가 존재하면 sum을 더해줌
            merged[key].sum += value.sum;
        } else {
            // 새로운 키면 그냥 추가
            merged[key] = value;
        }
    }

    return merged;
};

// 두 객체를 합치는 함수
const mergeMonthly = (obj1, obj2) => {
    const merged = { ...obj1 };

    for (const [key, value] of Object.entries(obj2)) {
        if (merged[key]) {
            // 이미 키가 존재하면 sum을 더해줌
            merged[key].sum += value.sum;
        } else {
            // 새로운 키면 그냥 추가
            merged[key] = value;
        }
    }

    return merged;
};

// 배정 공수
export const getDeptAssign = (perform_dept_cd, year) => async (dispatch) => {
    const target = 'deptAssign'; // target을 설정
    let depth = ''
    dispatch(fetchDataBegin(target));

    try {
        
        // 단계 1: 부서 코드 목록 가져오기
        const responseDeptCodes = await axios.get(`${API_URL}/deptMemberAll/${perform_dept_cd}`);
        dispatch(setDeptCodes(responseDeptCodes.data));
        const deptCodes = responseDeptCodes.data
            .filter(dept => {
                if (dept.dept_cd === String(perform_dept_cd) && dept.dept_lvl === 3) {
                    depth = '3';
                    return false; // 해당 요소는 제외
                }
                return true; // 그 외의 요소는 유지
            })
            .map(dept => dept.dept_cd);

        if (depth !== '3') {
            const response = await axios.get(`${API_URL}/deptAssign/${perform_dept_cd}/${year || ''}`);
            dispatch(fetchDataSuccess(response.data, target));
        } else {
            const promises = deptCodes.map(async (code) => {
                try {
                    const response = await axios.get(`${API_URL}/deptAssign/${code}/${year || ''}`);
                    return response.data;
                } catch (error) {
                    // 에러 처리 또는 기본값 반환
                    return {};
                }
            });

            const results = await Promise.all(promises);

            // results, stat_project, stat_monthly를 각각 합치기
            const mergedResults = results.reduce((acc, curr) => {
                acc.results = acc.results.concat(curr.results || []);
                acc.stat_project = mergeProjects(acc.stat_project, curr.stat_project);
                acc.stat_monthly = mergeMonthly(acc.stat_monthly, curr.stat_monthly);
                

                return acc;
            }, { results: [], stat_project: {}, stat_monthly: {} });
            // console.log(mergedResults.results)

            // console.log(mergedResults);
            dispatch(fetchDataSuccess(mergedResults, target)); // 배열 형태로 결과를 다시 dispatch
        }
        
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
    }
};

// 전체 배정 공수
export const getDeptAssignAll = (year) => async (dispatch) => {
    const target = 'deptAssignAll'; // target을 설정
    dispatch(fetchDataBegin(target));

    try {
        const response = await axios.get(`${API_URL}/deptAssignAll/${year || ''}`);
        dispatch(fetchDataSuccess(response.data, target));
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
    }
};

// 조직 배정 공수
export const getDeptAssignSingle = (perform_dept_cd, year) => async (dispatch) => {
    const target = 'deptAssignAllSingle'; // target을 설정
    dispatch(fetchDataBegin(target));

    try {
        const response = await axios.get(`${API_URL}/deptAssignAllSingle/${perform_dept_cd}/${year || ''}`);
        dispatch(fetchDataSuccess(response.data, target));
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
    }
};


// 투입 공수
export const getDeptUse = (perform_dept_cd, year) => async (dispatch) => {
    const target = 'deptUse'; // target을 설정
    dispatch(fetchDataBegin(target));

    try {
        const response = await axios.get(`${API_URL}/deptUse/${perform_dept_cd}/${year || ''}`);
        dispatch(fetchDataSuccess(response.data, target));
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
    }
};

// 조직 현황
export const getDeptStat = (perform_dept_cd, year) => async (dispatch) => {
    const target = 'deptStat'; // 타겟 설정
    dispatch(fetchDataBegin(target));

    let combinedData = Array.from({ length: 12 }, () => ({
        year: year || '',
        dept_cd: perform_dept_cd,
        gbn_cd: 'M',
        emp_cnt: 0,
        busi_time: 0,
        dept_personal_time: 0,
        hody_time: 0,
        remain_time: 0,
        total_time: 0,
        sch_now: 0,
        sch_mm: 0,
        assign_status: 0,
        assign_ratio: 0,
        run_ratio: 0,
        total_ratio: 0,
    }));

    try {
        // deptCodes가 하나만 있는 경우, 직접 데이터 할당
        const response = await axios.get(`${API_URL}/deptStat/${perform_dept_cd}/${year || ''}`);
        const responseData = response.data.results;
        responseData.forEach((data) => {
            const monthIndex = parseInt(data.mon, 10) - 1;
            if (monthIndex >= 0 && monthIndex < 12) {
                combinedData[monthIndex] = {
                    emp_cnt: data.emp_cnt,
                    busi_time: data.busi_time,
                    dept_personal_time: data.dept_personal_time,
                    hody_time: data.hody_time,
                    remain_time: data.remain_time,
                    total_time: data.total_time,
                    sch_now: data.sch_now,
                    sch_mm: data.sch_mm,
                    assign_status: data.assign_status,
                    assign_ratio: data.assign_ratio,
                    run_ratio: data.run_ratio,
                    total_ratio: data.total_ratio,
                };
            }
        });

        // 다른 부서의 데이터를 단일 배열로 결합
        const results = { results: combinedData, period: response.data.period, deptMembers: response.data.deptMembers}

        dispatch(fetchDataSuccess(results, target));
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
    }
};

export const getDepts = (perform_dept_cd) => async (dispatch) => {
    const target = 'depts'; // 타겟 설정
    dispatch(fetchDataBegin(target));

    try {
        const responseDeptCodes = await axios.get(`${API_URL}/deptMemberAll/${perform_dept_cd}`);
        const results = { results: responseDeptCodes.data };

        dispatch(fetchDataSuccess(results, target));
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
    }
};

export const getDeptMemberStat = (perform_dept_cd, year, mon) => async (dispatch) => {
    const target = 'deptMemberStat'; // 타겟 설정
    dispatch(fetchDataBegin(target));

    try {
        const response = await axios.get(`${API_URL}/deptMemberStat/${perform_dept_cd}/${year}/${mon}`);
        const results = { results: response.data };

        dispatch(fetchDataSuccess(results, target));
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
        dispatch(fetchDataSuccess({ data: [] }, target)); 
    }
};

export const getDeptMemberDetail = (empNo, year, mon) => async (dispatch) => {
    const target = 'deptMemberDetail'; // 타겟 설정
    dispatch(fetchDataBegin(target));

    try {
        const response = await axios.get(`${API_URL}/deptMemberDetail/${empNo}/${year}/${mon}`);
        const results = { results: response.data };

        dispatch(fetchDataSuccess(results, target));
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
    }
};

export const getDeptMemberDetail2 = (empNo, year, mon) => async (dispatch) => {
    const target = 'deptMemberDetail2'; // 타겟 설정
    dispatch(fetchDataBegin(target));

    try {
        const response = await axios.get(`${API_URL}/deptMemberDetail/${empNo}/${year}/${mon}`);
        const results = { results: response.data };

        dispatch(fetchDataSuccess(results, target));
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
    }
};

export const getPjtStat = (pjtNo) => async (dispatch) => {
    const target = 'pjtStat'; // 타겟 설정
    dispatch(fetchDataBegin(target));

    try {
        const responseDeptCodes = await axios.get(`${API_URL}/pjtStat/${pjtNo}`);
        const results = { results: responseDeptCodes.data };

        dispatch(fetchDataSuccess(results, target));
    } catch (error) {
        dispatch(fetchDataFailure(error, target));
    }
};


// 새로운 액션 생성자 함수 추가: loadProjects 액션과 관련된 액션들
export const loadProjects = (data) => async (dispatch) => {
    try {
        dispatch(loadProjectsSuccess(data));
    } catch (error) {
        dispatch(loadProjectsError(error));
    }
};

export const loadProjectsSuccess = (data) => ({
    type: LOAD_PROJECTS_SUCCESS,
    payload: data,
});

export const loadProjectsError = (error) => ({
    type: LOAD_PROJECTS_ERROR,
    payload: error,
});
