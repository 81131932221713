import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify'; // 알림 메시지를 표시하기 위한 라이브러리
import { Table } from 'semantic-ui-react'; // 시맨틱 UI의 테이블 컴포넌트
import 'semantic-ui-css/semantic.min.css'; // 시맨틱 UI 스타일
import 'react-toastify/dist/ReactToastify.css'; // 알림 메시지 스타일
import axios from 'axios'; // HTTP 요청을 위한 라이브러리
import { projectsLoading, projectsLoaded, projectsLoadError } from '../store/dataSlice'; // Redux 액션 및 상태 관리
import { getDeptAssign, getDeptAssignAll, getDeptAssignSingle, getDeptUse, getDeptStat, getDepts, getDeptMemberStat, getDeptMemberDetail, getDeptMemberDetail2, getPjtStat, API_URL2 } from '../actions/dataActions'; // 데이터 관련 액션 및 API 호출
import '../App.scss'; // 스타일 파일
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import koLocale from '@fullcalendar/core/locales/ko';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { toPng } from 'html-to-image';

import Chart from './Chart'
import Chart2 from './Chart2'
import DeptMemberStat from './DeptMemberStat'
import DeptMemberStatDetail from './DeptMemberStatDetail'
import DeptMemberStatDetail2 from './DeptMemberStatDetail2'
import SearchBar from './SearchBar'
import ApexCharts from 'react-apexcharts';

const Resource = () => {

  const ref = useRef()

  const onButtonClick = useCallback((title) => {
    setIsPrint(true)
    setTimeout(() => {
      if (ref.current === null) {
        setIsPrint(false)
        return
      }

      toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = title + '.png'
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          setIsPrint(false)
        })
    }, 500);

  }, [ref])

  const currentYear = new Date().getFullYear();
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
  // const prevMonth = (new Date().getMonth()).toString().padStart(2, '0');

  // 상태 변수 선언
  const [login, setLogin] = useState(false);
  const [deptCd, setDeptCd] = useState(null);
  const [deptNm, setDeptNm] = useState(null);
  const [year, setYear] = useState(currentYear);
  const [year2, setYear2] = useState(currentYear);
  const [mon, setMon] = useState(currentMonth);
  const [mon2, setMon2] = useState(null);
  const [empNo, setEmpNo] = useState(null);
  const [empNo2, setEmpNo2] = useState(null);
  const [empNm, setEmpNm] = useState(null);
  const [empNm2, setEmpNm2] = useState(null);
  const [dutyNm, setDutyNm] = useState(null);
  const [workDay, setWorkDay] = useState(null);
  const [workDay2, setWorkDay2] = useState(null);
  const [pjtNo, setPjtNo] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);


  // Redux에서 상태 가져오기
  const deptAssign = useSelector(state => state.deptAssign || []);
  const deptAssignAll = useSelector(state => state.deptAssignAll || []);
  const deptAssignAllSingle = useSelector(state => state.deptAssignAllSingle || []);
  const deptUse = useSelector(state => state.deptUse || []);
  const deptStat = useSelector(state => state.deptStat || []);
  const depts = useSelector(state => state.depts || []);
  const deptMemberStat = useSelector(state => state.deptMemberStat || []);
  const deptMemberDetail = useSelector(state => state.deptMemberDetail || []);
  const deptMemberDetail2 = useSelector(state => state.deptMemberDetail2 || []);
  const pjtStat = useSelector(state => state.pjtStat || []);
  const dispatch = useDispatch();

  // Tab state 추가
  const [activeTab, setActiveTab] = useState('monthly');

  // 12개의 0으로 초기화된 배열을 생성하는 함수
  const initData = () => Array(12).fill(0);

  // 첫 번째 차트 데이터 초기화
  const seriesData = [
    { name: '보유', data: initData() },
    { name: '업무', data: initData() },
    { name: '배정', data: initData() },
  ];

  // 두 번째 차트 데이터 초기화
  const seriesData2 = [
    { name: '보유', type: 'line', data: initData() },
    { name: '업무', type: 'column', data: initData() },
    { name: '조직 및 개인', type: 'column', data: initData() },
    { name: '근태', type: 'column', data: initData() },
  ];

  // 세 번째 차트 데이터 초기화
  const seriesData3 = [
    { name: '계약', data: [] },
    // { name: '평단', data: [] },
    // { name: '배정', data: initData() },
  ];

  // 네 번째 차트 데이터 초기화
  const seriesData4 = [
    { name: '계약', data: [] },
    // { name: '평단', data: [] },
    // { name: '배정', data: initData() },
  ];
  const seriesData5 = [
    { name: '계약', data: [] },
    // { name: '평단', data: [] },
    // { name: '배정', data: initData() },
  ];
  const seriesData6 = [
    { name: '계약', data: [] },
    // { name: '평단', data: [] },
    // { name: '배정', data: initData() },
  ];

  // 알림 메시지 표시 함수
  const showToast = txt => {
    toast.info(txt, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      theme: 'light',
    });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.key === 'b') {
        setIsVisible3(true);
      }
    };

    // 이벤트 리스너를 추가합니다.
    window.addEventListener('keydown', handleKeyPress);

    // 컴포넌트가 언마운트 될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isVisible3]);

  // 로그인 처리 함수
  const loginProcess = useCallback(async () => {
    try {
      const res = await axios.get(`${API_URL2}/api/user/me`, {
        withCredentials: true,
      });
      const data = res.data.data.info;
      // data.deptCd = 184
      // data.deptCd = 234
      // data.deptCd = 100
      // data.deptCd = 247
      // data.deptCd = 318
      if (data.empNm !== '') {
        // setEmpNo(data.empNo);
        setDeptCd(data.deptCd);
        setDeptNm(data.deptNm)
        if (
          ['실장', '본부장', '센터장', '대표이사'].includes(data.dutyNm) ||
          data.deptCd === '219' ||
          data.deptCd === '361'
          // || data.empNo === 'HL0325'
        ) {
          setLogin(true);
        } else {
          showToast('서비스 접근 권한 오류입니다. 문의해주세요.');
        }
      }
    } catch (error) {
      if (error.response?.status === 440) {
        showToast('로그인 인증 오류입니다. HRMS 로그인 후에 다시 시도해주세요.');
        setTimeout(() => {
          window.open(`${API_URL2}/`);
        }, 2000);
      }
    }
  }, []);

  // 컴포넌트가 마운트될 때 로그인 처리 실행
  useEffect(() => {
    loginProcess();
  }, [loginProcess]);

  // 데이터 가져오기 함수
  const fetchData = async (getDeptFunction, target, parameters) => {
    dispatch(projectsLoading({ target }));

    try {
      if (deptCd !== null) {
        const response = await dispatch(getDeptFunction(...parameters));
        dispatch(projectsLoaded({ data: response.data, target }));
      }
    } catch (error) {
      dispatch(projectsLoadError({ error, target }));
    }
  };

  // 컴포넌트가 마운트될 때 초기 데이터 로딩
  useEffect(() => {
    if (deptCd) {
      const commonParameters = [year, mon];
      fetchData(getDeptAssign, 'projects', [deptCd, ...commonParameters]);
      fetchData(getDeptAssignAll, 'projectsAll', [year]);
      fetchData(getDeptAssignSingle, 'projectsAllSingle', [deptCd, year]);
      fetchData(getDeptUse, 'projects2', [deptCd, ...commonParameters]);
      fetchData(getDeptStat, 'projects3', [deptCd, ...commonParameters]);
      fetchData(getDeptMemberStat, 'deptMemberStat', [deptCd, ...commonParameters]);
      // fetchData(getDeptMemberDetail, 'deptMemberDetail', [empNo, ...commonParameters]);
      // 부서 데이터가 없는 경우에만 부서 데이터 로딩
      if (depts.data.length === 0) {
        fetchData(getDepts, 'depts', [deptCd]);
      }
    }
    // }, [dispatch, deptCd, empNo, year, mon, depts.data.length]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deptCd, year]);

  // 결과 및 통계 데이터 추출
  const results = useMemo(() => deptAssign.data.results || [], [deptAssign.data.results]);
  const stat_data = deptAssign.data.stat_project;
  const stat_monthly = deptAssign?.data.stat_monthly;

  const results2 = useMemo(() => deptUse.data.results || [], [deptUse.data.results]);
  const stat_data2 = deptUse.data.stat_project;
  const stat_monthly2 = deptUse?.data.stat_monthly;

  const results3 = deptStat.data.results;
  const deptStatData = deptStat.data;
  const stat_data3 = deptStat.data.stat_dept;
  const deptYear = deptStat.data.period;
  const deptMember = deptMemberStat.data.results;
  let memberDetail = deptMemberDetail.data.results;
  let memberDetail2 = deptMemberDetail2.data.results;
  const pjtStatResult = pjtStat.data.results;

  const deptAssignAllResult = deptAssignAll.data
  const deptAssignSingleResult = deptAssignAllSingle.data

  if (deptAssignAllResult) {
    deptAssignAllResult.sort((a, b) => {
      const aPrice = a.avg_price ? a.avg_price.replace(/,/g, '') : 0; // null 또는 undefined 처리
      const bPrice = b.avg_price ? b.avg_price.replace(/,/g, '') : 0; // null 또는 undefined 처리
      return bPrice * b.total_sch_mm - aPrice * a.total_sch_mm;
    });
  }
  
  if (deptAssignSingleResult) {
    deptAssignSingleResult.sort((a, b) => {
      const aPrice = a.avg_price ? a.avg_price.replace(/,/g, '') : 0; // null 또는 undefined 처리
      const bPrice = b.avg_price ? b.avg_price.replace(/,/g, '') : 0; // null 또는 undefined 처리
      return bPrice * b.total_sch_mm - aPrice * a.total_sch_mm;
    });
  }
  
  
  // console.log(deptAssignSingleResult)

  let uniqueProjectArr = [];

  if (results) {
    // 유니크한 값을 저장하기 위한 Set 객체 생성
    const uniqueSet = new Set();

    // results 배열을 순회하며 각 아이템의 clt_nm 값을 Set에 추가
    results.forEach(item => {
      uniqueSet.add(item.pjt_no); // clt_nm이 유니크한 값을 추출하고자 하는 키라고 가정
    });

    // Set을 배열로 변환
    uniqueProjectArr = Array.from(uniqueSet);
  }
    // console.log(uniqueProjectArr)

    // depAssignAllResult 배열에서 pjt_nm이 uniqueProjectArr에 포함된 요소만 필터링
    const filteredResults = deptAssignAllResult.filter(item => uniqueProjectArr.includes(item.pjt_no));
    // console.log(filteredResults); // 필터링된 결과를 콘솔에 출력

    // console.log(filteredResults)

    // filteredResults 배열에서 중복을 제거하고 계산을 수행
    const summarizedResults = Object.values(filteredResults.reduce((acc, item) => {
      // clt_nm을 기준으로 그룹화
      if (!acc[item.clt_nm]) {
        acc[item.clt_nm] = {
          pjt_no: [],
          clt_nm: item.clt_nm,
          pjt_nm: [],
          busi_mm: 0,
          avg_price: 0,
          busi_price: 0,
          total_sch_mm: 0,
          count: 0
        };
      }
    
      // avg_price에서 콤마를 제거하고 숫자형으로 변환, 그 후 합산
      const numericAvgPrice = parseFloat(item.avg_price.replace(/,/g, ''));
      acc[item.clt_nm].avg_price += numericAvgPrice;
    
      // busi_mm과 busi_price는 이미 숫자형이라고 가정하고 합산
      acc[item.clt_nm].busi_mm += item.busi_mm;
      acc[item.clt_nm].busi_price += item.busi_price;
      
      // 나머지 필드들은 배열에 추가
      acc[item.clt_nm].pjt_no.push(item.pjt_no);
      acc[item.clt_nm].pjt_nm.push(item.pjt_nm);
      acc[item.clt_nm].total_sch_mm += item.total_sch_mm;
      acc[item.clt_nm].count += 1;
    
      return acc;
    }, {})).map(group => ({
      pjt_no: group.pjt_no,
      clt_nm: group.clt_nm,
      pjt_nm: group.pjt_nm,
      busi_mm: group.busi_mm,
      avg_price: (group.avg_price / group.count).toFixed(2), // 평균을 계산하고 소수점 둘째자리로 반올림
      busi_price: group.busi_price,
      total_sch_mm: group.total_sch_mm
    }));
    

    // summarizedResults 배열 정의 부분 이후에 다음 코드 추가
    /* summarizedResults.sort((a, b) => {
      // b의 busi_price와 a의 busi_price를 비교하여 내림차순 정렬
      return b.avg_price.replace(/,/g, '') * b.total_sch_mm - a.avg_price.replace(/,/g, '') * a.total_sch_mm;
    }); */

    // console.log(summarizedResults); // 결과를 콘솔에 출력

  // depAssignAllResult.filter(item => item.pjt_nm = uniqueProjectArr)

/*   let filteredDeptMembers = {}
  if (deptMember) {
    filteredDeptMembers = deptMember.results.filter(member => Number(member.duty_cd) > 50);
  } */

  // console.log(memberDetail)
  // console.log(memberDetail2)

  useEffect(() => {
    fetchData(getDeptMemberStat, 'deptMemberStat', [deptCd, year, mon]);
    fetchData(getDeptMemberDetail, 'deptMemberDetail', [empNo, year, mon]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empNm, year, mon]);

  useEffect(() => {
    if (empNo2 && year2 && mon2) {
      fetchData(getDeptMemberDetail2, 'deptMemberDetail2', [empNo2, year2, mon2]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empNo2, year2, mon2]);

  useEffect(() => {
    if (pjtNo) {
      fetchData(getPjtStat, 'pjtStat', [pjtNo]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pjtNo]);

  const processMemberData = useCallback((memberDetail, year, mon) => {

    function getDatesInRange(startDate, endDate, year, mon) {
      if (!startDate || !endDate || !year || !mon) {
        console.error("Invalid parameters for getDatesInRange:", { startDate, endDate, year, mon });
        return [];
      }

      const dateArray = [];
      let current = new Date(startDate);
      const end = new Date(endDate);

      if (isNaN(current.getTime()) || isNaN(end.getTime())) {
        console.error("Invalid date format:", { startDate, endDate });
        return [];
      }

      while (current <= end) {
        const isoString = current.toISOString();
        if (isoString && isoString.includes('T')) {
          const dateStr = isoString.split('T')[0];
          if (current.getFullYear().toString() === year &&
            (current.getMonth() + 1).toString().padStart(2, '0') === mon) {
            dateArray.push(dateStr);
          }
        } else {
          console.error("Failed to format date:", current);
        }
        current.setDate(current.getDate() + 1);
      }

      return dateArray;
    }

    function getDatesInRange2(bgnDt, endDt) {
      if (!bgnDt || !endDt) {
        console.error("Invalid parameters for getDatesInRange2:", { bgnDt, endDt });
        return [];
      }

      const dates = [];
      let current = new Date(bgnDt);
      const end = new Date(endDt);

      if (isNaN(current.getTime()) || isNaN(end.getTime())) {
        console.error("Invalid date format:", { bgnDt, endDt });
        return [];
      }

      while (current <= end) {
        const isoString = current.toISOString();
        if (isoString && isoString.includes('T')) {
          const dateStr = isoString.split('T')[0].substring(8, 10);
          if (!weekendDays?.has(dateStr)) {
            dates.push(dateStr);
          }
        } else {
          console.error("Failed to format date:", current);
        }
        current.setDate(current.getDate() + 1);
      }

      return dates;
    }
  

    let holidayDates = {};
    let weekendDays = {};
    let vctnData = [];

    if (memberDetail) {
      memberDetail.vctn.forEach(item => {
        if (item.vctn_use_days === null) {
          item.rsn = '오아시스';
          item.vctn_use_days = 0.25;
        }
      });
    }

    if (memberDetail) {
      holidayDates = memberDetail.hody.flatMap(holiday => {
        const startDate = new Date(holiday.begin_dt);
        const endDate = new Date(holiday.end_dt);
        return getDatesInRange(startDate, endDate, year, mon);
      }).map(date => date.slice(-2));

      weekendDays = new Set([
        ...memberDetail.wknd.map(wknd => wknd.day),
        ...holidayDates
      ]);

      memberDetail.vctn.forEach(item => {
        let bgn_dt = new Date(item.bgn_dt)
        let end_dt = new Date(item.end_dt)
        bgn_dt.setDate(bgn_dt.getDate() + 1)
        end_dt.setDate(end_dt.getDate() + 1)

        const datesInRange = getDatesInRange2(bgn_dt, end_dt);

        datesInRange.forEach(date => {
          if (!weekendDays.has(date)) {
            let useDays = item.vctn_use_days;
            if (useDays > 1) {
              if (date === bgn_dt.getDate().toString().padStart(2, '0') && item.bgn_am_pm === 'P') {
                useDays = 4;
              } else if (date === end_dt.getDate().toString().padStart(2, '0') && item.end_am_pm === 'A') {
                useDays = 4;
              } else {
                useDays = 8;
              }
            } else if (useDays === 1) {
              useDays = 8;
            } else if (useDays === 0.5) {
              useDays = 4;
            } else if (item.eltr_appv_grp_cd === 'E0030010030010') {
              useDays = 2;
            } else {
              useDays = 0;
            }

            vctnData.push({
              date: date,
              mon: mon,
              year: year,
              'pjt_nm': '근태',
              'busi_desc': item.eltr_appv_grp_nm,
              'rs_use_time': useDays
            })
          }
        });
      });
    }
    // memberDetail.wknd의 주말 날짜와 holidayDates를 합쳐서 weekendDays Set에 저장

    vctnData.forEach(data => {
      memberDetail.resource.push(data);
    });

    let taskInfo = []
    let uniqueTaskInfo = []
    let updatedUniqueTaskInfo = []
    let updatedUniqueTaskInfo2 = []
    let simplifiedTaskInfo = []
    let totalSum = []
    let sumData = []
    let sumByProjectArray = []
    let rowSpans = []
    let sumsArray = []
    let assignSum = 0;

    // memberDetail.resource에서 pjt_nm과 busi_desc만 추출하여 배열에 저장
    taskInfo = memberDetail ? memberDetail.resource.map(item => ({
      pjt_no: item.pjt_no,
      pjt_nm: item.pjt_nm,
      busi_desc: item.busi_desc
    })) : [];

    // 중복 데이터 제거
    uniqueTaskInfo = taskInfo.reduce((unique, item) => {
      // 이미 배열에 있는지 확인
      const found = unique.some(existingItem =>
        existingItem.pjt_nm === item.pjt_nm && existingItem.busi_desc === item.busi_desc
      );

      // 배열에 없는 경우에만 추가
      if (!found) {
        unique.push(item);
      }

      return unique;
    }, []);
    // fetchData(getDeptMemberDetail, 'deptMemberDetail', ['hl0363', year, mon]);
    if (memberDetail && memberDetail.resource) {
      memberDetail.resource.forEach(item => {
        uniqueTaskInfo.forEach(info => {
          if (info.pjt_nm === item.pjt_nm && info.busi_desc === item.busi_desc) {
            info[item.date] = item.rs_use_time;
          }
        });
      });
    }


    const addSumProperty = (data) => {
      return data.map(item => {
        let sum = 0;

        // 객체의 키를 순회하며 2자리 숫자인 키의 값들을 합산
        Object.keys(item).forEach(key => {
          if (key.match(/^\d{2}$/)) {
            sum += item[key];
          }
        });

        // 합산된 값을 sum 속성에 추가
        return { ...item, sum };
      });
    };

    // uniqueTaskInfo 배열에 대해 함수를 적용
    updatedUniqueTaskInfo = addSumProperty(uniqueTaskInfo);

    if (results && updatedUniqueTaskInfo) {
      results.forEach(item => {
        updatedUniqueTaskInfo.forEach(info => {
          if (info.pjt_nm === item.pjt_nm && mon === item.sch_month) {
            info.sch_mm = item.sch_mm
          }
        })
      })
    }

    updatedUniqueTaskInfo2 = updatedUniqueTaskInfo.reduce((acc, item) => {
      if (!acc[item.pjt_nm]) {
        acc[item.pjt_nm] = item;
      }
      return acc;
    }, {});

    simplifiedTaskInfo = Object.values(updatedUniqueTaskInfo2).map(item => ({
      sch_mm: item.sch_mm
    }));

    if (simplifiedTaskInfo) {
      simplifiedTaskInfo.forEach(item => {
        if (item.sch_mm) {
          assignSum += item.sch_mm;
        }
      });
      assignSum = parseFloat(assignSum.toFixed(2));
    }

    sumData = updatedUniqueTaskInfo.reduce((acc, item) => {
      Object.keys(item).forEach(key => {
        if (key.match(/^\d{2}$/)) { // 2자리 숫자 키를 확인합니다.
          acc[key] = (acc[key] || 0) + item[key];
        }
      });
      return acc;
    }, {});

    sumByProjectArray = updatedUniqueTaskInfo.reduce((acc, item) => {
      // pjt_nm에 따라 그룹화
      if (!acc[item.pjt_nm]) {
        acc[item.pjt_nm] = 0;
      }

      // 2자리 숫자 키를 확인하고 합산
      Object.keys(item).forEach(key => {
        if (key.match(/^\d{2}$/)) {
          acc[item.pjt_nm] += item[key];
        }
      });

      return acc;
    }, {});


    // 계산된 합계들을 배열로 변환
    // const sumsArray = Object.values(sumByProjectArray);

    totalSum = Object.values(sumData).reduce((acc, value) => acc + value, 0);

    // 배열 정렬
    function sortByArr(array) {
      return array.sort((a, b) => {
        if (a.pjt_nm < b.pjt_nm) {
          return -1;
        }
        if (a.pjt_nm > b.pjt_nm) {
          return 1;
        }
        return 0;
      });
    }

    sortByArr(updatedUniqueTaskInfo);

    // 연속 중복 열 제목을 찾아 rowSpan 값으로 변환
    rowSpans = updatedUniqueTaskInfo.map((header, i) => {
      if (i > 0 && header.pjt_nm === updatedUniqueTaskInfo[i - 1].pjt_nm) {
        return 0; // 이전 헤더와 동일하면 0을 반환 (렌더링하지 않음)
      }
      // 연속 중복 카운트를 계산
      let span = 1;
      for (let j = i + 1; j < updatedUniqueTaskInfo.length && header.pjt_nm === updatedUniqueTaskInfo[j].pjt_nm; j++) {
        span++;
      }
      return span;
    });

    // updatedUniqueTaskInfo 길이만큼의 배열을 생성하고 모든 값을 0으로 초기화
    sumsArray = new Array(updatedUniqueTaskInfo.length).fill(0);

    // rowSpans의 각 값에 대해 처리
    rowSpans.forEach((span, index) => {
      if (span !== 0) {
        const projectName = updatedUniqueTaskInfo[index].pjt_nm;
        const projectSum = sumByProjectArray[projectName];
        sumsArray[index] = projectSum;
      }
    });
    return {
      memberDetail,
      totalSum,
      workDay,
      assignSum,
      sumData,
      updatedUniqueTaskInfo,
      sumByProjectArray,
      sumsArray,
      rowSpans,
      weekendDays
    }
  }, [results, workDay]);

  // memberDetail에 대한 데이터 처리
  // const memberDetailProcessed = processMemberData(memberDetail, year, mon);
  const memberDetailProcessed = useMemo(() => processMemberData(memberDetail, year, mon), [processMemberData, memberDetail, year, mon]);

  // memberDetail2에 대한 데이터 처리
  // const memberDetail2Processed = processMemberData(memberDetail2, year2, mon2);
  const memberDetail2Processed = useMemo(() => processMemberData(memberDetail2, year2, mon2), [processMemberData, memberDetail2, year2, mon2]);

  let filteredDeptMembers = {}
  if (deptMember) {
    filteredDeptMembers = deptMember.results.filter(member => Number(member.duty_cd) > 30);
  }

  if (deptMember && deptMember.results.length && workDay === null) {
    setWorkDay(deptMember.results[0].workday)
  }

  let emp_Sum = 0;
  if (results3 && results3.length > 0) {
    results3.forEach((item) => {
      emp_Sum += item?.emp_cnt || 0;
    });
  }

  let dept_menu = depts.data.results

  const DepartmentItem = ({ dept, deptCd, handleButtonClick }) => {
    return (
      <li>
        <button
          type="button"
          dept={dept.dept_cd}
          className={`item ${String(deptCd) === dept.dept_cd || deptCd === dept.dept_cd ? 'active' : ''}`}
          onClick={() => handleButtonClick(dept.dept_cd)}
        >
          {dept.dept_nm}
        </button>
        {dept.subDept && dept.subDept.length > 0 && (
          <ul>
            {dept.subDept.map((subItem, subIndex) =>
              <DepartmentItem key={subIndex} dept={subItem} deptCd={deptCd} handleButtonClick={handleButtonClick} />
            )}
          </ul>
        )}
      </li>
    );
  };


  const createAndSortHierarchy = (departments) => {
    // 부서를 레벨과 이름으로 정렬
    departments.sort((a, b) => {
      if (a.dept_lvl !== b.dept_lvl) {
        return a.dept_lvl - b.dept_lvl;
      } else {
        return a.dept_nm.localeCompare(b.dept_nm);
      }
    });

    const deptDict = {};
    departments.forEach(dept => {
      deptDict[dept.dept_cd] = { ...dept, subDept: [] };
    });

    const rootDepartments = [];
    departments.forEach(dept => {
      if (deptDict[dept.up_dept_cd]) {
        deptDict[dept.up_dept_cd].subDept.push(deptDict[dept.dept_cd]);
      } else {
        rootDepartments.push(deptDict[dept.dept_cd]);
      }
    });

    return rootDepartments;
  };

  if (dept_menu && dept_menu.length > 0) {
    dept_menu = createAndSortHierarchy(dept_menu);
  }

  const monthColumns = Array.from({ length: 12 }, (_, i) =>
    `${String(i + 1).padStart(2, '0')}월`
  );

  const rtnStat = (results, start, end, period, x, y) => {
    let sumItemRatio = 0;

    // results3 배열에서 emp_cnt가 0보다 큰 요소들의 개수 계산
    const dataCnt = results3.filter((item) => item.emp_cnt > 0).length;

    // currentYear과 year 변수는 함수 내에서 정의되거나 외부에서 받아야 함
    let modifiedPeriod = period;  // 기본적으로 period 사용

    if (currentYear === year) {
      modifiedPeriod = dataCnt; // 조건 만족 시 dataCnt 사용
    }

    monthColumns.forEach((month, index) => {
      if (Number(month.slice(0, 2)) >= start && month.slice(0, 2) <= end) {
        sumItemRatio += results[index][x];
      }
    });

    // modifiedPeriod (period 또는 dataCnt)를 사용하여 계산
    return (sumItemRatio / modifiedPeriod) !== 0 && typeof (sumItemRatio / modifiedPeriod) === 'number'
      ? (sumItemRatio / modifiedPeriod).toFixed(2)
      : '';
  }


  const rtnStat2 = (results, start, end, x) => {
    let sumItemRatio = 0;
    monthColumns.forEach((month, index) => {
      if (Number(month.slice(0, 2)) >= start && month.slice(0, 2) <= end) {
        sumItemRatio += results[index][x]
      }
    })
    return sumItemRatio !== 0 && typeof sumItemRatio === 'number' ? sumItemRatio.toFixed(2) : ''
  }

  const rtnStat3 = (results, start, end, period, x, y) => {
    let sumItemRatio = 0;
    let sumItemRatio2 = 0;
    monthColumns.forEach((month, index) => {
      if (Number(month.slice(0, 2)) >= start && month.slice(0, 2) <= end) {
        sumItemRatio += results[index][x]
        sumItemRatio2 += results[index][y]
      }
    })
    return ((sumItemRatio / sumItemRatio2) / period) !== 0 && typeof ((sumItemRatio / sumItemRatio2) / period) === 'number' && !isNaN((sumItemRatio / sumItemRatio2) / period) ? ((sumItemRatio / sumItemRatio2) * 100).toFixed(2) + '%' : ''
  }

  const rtnStat4 = (results, start, end, x, y) => {
    let sumItemRatio = 0;
    monthColumns.forEach((month, index) => {
      if (Number(month.slice(0, 2)) >= start && month.slice(0, 2) <= end && results[index][x] > 0 && results[index][y] > 0) {
        sumItemRatio += (results[index][x] / results[index][y])
      }
    })

    return (sumItemRatio) !== 0 && typeof (sumItemRatio) === 'number' && !isNaN(sumItemRatio) ? ((sumItemRatio) * 100).toFixed(2) + '%' : ''
  }

  const renderDeptStat = (results, stat_dept) => {
    // if (!Array.isArray(results) || results.length === 0 || typeof stat_dept !== 'object') {
    if (!Array.isArray(results) || results.length === 0) {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="18">조회할 수 있는 데이터가 없습니다</Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    }

    const formatFixed1 = (value, show) => {
      if (value) {
        return value.toFixed(2);
      } else {
        if (show) {
          return 0;
        } else if (show !== false) {
          return '';
        }
      }
    };

    if (results.length > 0) {
      monthColumns.forEach((month, index) => {
        seriesData[0].data[index] = formatFixed1(results[index].emp_cnt, true);
        seriesData[1].data[index] = formatFixed1(results[index].busi_time, true);
        seriesData[2].data[index] = formatFixed1(results[index].sch_mm, true);

        seriesData2[0].data[index] = formatFixed1(results[index].emp_cnt, true);
        seriesData2[1].data[index] = formatFixed1(results[index].busi_time, true);
        seriesData2[2].data[index] = formatFixed1(results[index].dept_personal_time, true);
        seriesData2[3].data[index] = formatFixed1(results[index].hody_time, true);
      });
      filteredResults.forEach((item, index) => {
        seriesData3[0].data[index] = filteredResults[index].busi_price;
        // seriesData3[1].data[index] = filteredResults[index].avg_price;
        // seriesData3[2].data[index] = filteredResults[index].busi_mm;
      });
      
      filteredResults.forEach((item, index) => {
        const avgPrice = filteredResults[index].avg_price ? filteredResults[index].avg_price.replace(/,/g, '') : '0'; // null 처리
        seriesData4[0].data[index] = parseFloat(avgPrice) * filteredResults[index].total_sch_mm;
      });
      
      deptAssignAllResult.forEach((item, index) => {
        const avgPrice = deptAssignAllResult[index].avg_price ? deptAssignAllResult[index].avg_price.replace(/,/g, '') : '0'; // null 처리
        seriesData5[0].data[index] = parseFloat(avgPrice) * deptAssignAllResult[index].total_sch_mm;
      });
      
      deptAssignSingleResult.forEach((item, index) => {
        const avgPrice = deptAssignSingleResult[index].avg_price ? deptAssignSingleResult[index].avg_price.replace(/,/g, '') : '0'; // null 처리
        seriesData6[0].data[index] = parseFloat(avgPrice) * deptAssignSingleResult[index].total_sch_mm;
      });
      
      // seriesData6[0].data.sort((a, b) => b - a);
      // console.log(seriesData6)
    }
    return (
      <Table.Body>
        <Table.Row>
          {results3 && (
            <>
              <Table.Cell>배정률</Table.Cell>
              {/* monthColumns에 대한 코드 */}
              {monthColumns.map((project, index) => {

                return (
                  <Table.Cell key={index} className={getAssignRatioClass((results3[index].sch_mm / results3[index].emp_cnt) * 100)}>
                    {results3[index].assign_ratio !== 0 ? ((results3[index].sch_mm / results3[index].emp_cnt) * 100).toFixed(2) + '%' : ''}
                  </Table.Cell>
                );
              })}

              <Table.Cell className={getAssignRatioClass(rtnStat3(results3, 1, 6, 6, 'sch_mm', 'emp_cnt').slice(0, -1))}>{rtnStat3(results3, 1, 6, 6, 'sch_mm', 'emp_cnt')}</Table.Cell>
              <Table.Cell className={getAssignRatioClass(rtnStat3(results3, 7, 12, 6, 'sch_mm', 'emp_cnt').slice(0, -1))}>{rtnStat3(results3, 7, 12, 6, 'sch_mm', 'emp_cnt')}</Table.Cell>
              <Table.Cell className={getAssignRatioClass(rtnStat3(results3, 1, 12, 12, 'sch_mm', 'emp_cnt').slice(0, -1))}>{rtnStat3(results3, 1, 12, 12, 'sch_mm', 'emp_cnt')}</Table.Cell>
              <Table.Cell>{rtnStat4(results3, 1, 6, 'sch_mm', 'emp_cnt')}</Table.Cell>
              <Table.Cell>{rtnStat4(results3, 7, 12, 'sch_mm', 'emp_cnt')}</Table.Cell>
              <Table.Cell>{rtnStat4(results3, 1, 12, 'sch_mm', 'emp_cnt')}</Table.Cell>
            </>
          )}
        </Table.Row>

        <Table.Row>
          {results3 && (
            <>
              <Table.Cell>수행률</Table.Cell>
              {/* monthColumns에 대한 코드 */}
              {monthColumns.map((project, index) => {

                return (
                  <Table.Cell key={index}>
                    {results3[index].run_ratio !== 0 ? ((results3[index].busi_time / results3[index].sch_mm) * 100).toFixed(2) + '%' : ''}
                  </Table.Cell>
                );
              })}

              <Table.Cell>{rtnStat3(results3, 1, 6, 6, 'busi_time', 'sch_mm')}</Table.Cell>
              <Table.Cell>{rtnStat3(results3, 7, 12, 6, 'busi_time', 'sch_mm')}</Table.Cell>
              <Table.Cell>{rtnStat3(results3, 1, 12, 12, 'busi_time', 'sch_mm')}</Table.Cell>
              <Table.Cell>{rtnStat4(results3, 1, 6, 'busi_time', 'sch_mm')}</Table.Cell>
              <Table.Cell>{rtnStat4(results3, 7, 12, 'busi_time', 'sch_mm')}</Table.Cell>
              <Table.Cell>{rtnStat4(results3, 1, 12, 'busi_time', 'sch_mm')}</Table.Cell>
            </>
          )}
        </Table.Row>

        <Table.Row>
          {results3 && (
            <>
              <Table.Cell>가동률</Table.Cell>
              {/* monthColumns에 대한 코드 */}
              {monthColumns.map((project, index) => {

                return (
                  <Table.Cell key={index}>
                    {results3[index].run_ratio !== 0 ? ((results3[index].total_time / results3[index].emp_cnt) * 100).toFixed(2) + '%' : ''}
                  </Table.Cell>
                );
              })}

              <Table.Cell>{rtnStat3(results3, 1, 6, 6, 'total_time', 'emp_cnt')}</Table.Cell>
              <Table.Cell>{rtnStat3(results3, 7, 12, 6, 'total_time', 'emp_cnt')}</Table.Cell>
              <Table.Cell>{rtnStat3(results3, 1, 12, 12, 'total_time', 'emp_cnt')}</Table.Cell>
              <Table.Cell>{rtnStat4(results3, 1, 6, 'total_time', 'emp_cnt')}</Table.Cell>
              <Table.Cell>{rtnStat4(results3, 7, 12, 'total_time', 'emp_cnt')}</Table.Cell>
              <Table.Cell>{rtnStat4(results3, 1, 12, 'total_time', 'emp_cnt')}</Table.Cell>
            </>
          )}
        </Table.Row>
        <Table.Row>
          <Table.Cell>보유</Table.Cell>
          {monthColumns.map((project, index) => (
            <Table.Cell key={index}>{formatFixed1(results[index].emp_cnt, false)}</Table.Cell>
          ))}
          <Table.Cell>{rtnStat(results3, 1, 6, 6, 'emp_cnt')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 7, 12, 6, 'emp_cnt')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 1, 12, 12, 'emp_cnt')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 6, 'emp_cnt')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 7, 12, 'emp_cnt')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 12, 'emp_cnt')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>배정</Table.Cell>
          {monthColumns.map((project, index) => (
            <Table.Cell key={index}>{formatFixed1(results[index].sch_mm, false)}</Table.Cell>
          ))}
          <Table.Cell>{rtnStat(results3, 1, 6, 6, 'sch_mm')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 7, 12, 6, 'sch_mm')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 1, 12, 12, 'sch_mm')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 6, 'sch_mm')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 7, 12, 'sch_mm')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 12, 'sch_mm')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>보유-배정</Table.Cell>
          {monthColumns.map((project, index) => (
            <Table.Cell key={index}>{formatFixed1(results[index].sch_now, false)}</Table.Cell>
          ))}
          <Table.Cell>{rtnStat(results3, 1, 6, 6, 'sch_now')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 7, 12, 6, 'sch_now')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 1, 12, 12, 'sch_now')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 6, 'sch_now')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 7, 12, 'sch_now')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 12, 'sch_now')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>업무</Table.Cell>
          {monthColumns.map((project, index) => (
            <Table.Cell key={index}>{formatFixed1(results[index].busi_time, false)}</Table.Cell>
          ))}
          <Table.Cell>{rtnStat(results3, 1, 6, 6, 'busi_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 7, 12, 6, 'busi_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 1, 12, 12, 'busi_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 6, 'busi_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 7, 12, 'busi_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 12, 'busi_time')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>조직 및 개인</Table.Cell>
          {monthColumns.map((project, index) => (
            <Table.Cell key={index}>{formatFixed1(results[index].dept_personal_time, false)}</Table.Cell>
          ))}
          <Table.Cell>{rtnStat(results3, 1, 6, 6, 'dept_personal_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 7, 12, 6, 'dept_personal_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 1, 12, 12, 'dept_personal_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 6, 'dept_personal_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 7, 12, 'dept_personal_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 12, 'dept_personal_time')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>근태</Table.Cell>
          {monthColumns.map((project, index) => (
            <Table.Cell key={index}>{formatFixed1(results[index].hody_time, false)}</Table.Cell>
          ))}
          <Table.Cell>{rtnStat(results3, 1, 6, 6, 'hody_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 7, 12, 6, 'hody_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 1, 12, 12, 'hody_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 6, 'hody_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 7, 12, 'hody_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 12, 'hody_time')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>합계</Table.Cell>
          {monthColumns.map((project, index) => (
            <Table.Cell key={index}>{formatFixed1(results[index].total_time, false)}</Table.Cell>
          ))}
          <Table.Cell>{rtnStat(results3, 1, 6, 6, 'total_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 7, 12, 6, 'total_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 1, 12, 12, 'total_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 6, 'total_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 7, 12, 'total_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 12, 'total_time')}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>잔여</Table.Cell>
          {monthColumns.map((project, index) => (
            <Table.Cell key={index}>{formatFixed1(results[index].remain_time, false)}</Table.Cell>
          ))}
          <Table.Cell>{rtnStat(results3, 1, 6, 6, 'remain_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 7, 12, 6, 'remain_time')}</Table.Cell>
          <Table.Cell>{rtnStat(results3, 1, 12, 12, 'remain_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 6, 'remain_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 7, 12, 'remain_time')}</Table.Cell>
          <Table.Cell>{rtnStat2(results3, 1, 12, 'remain_time')}</Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  };

  const handleButtonClick = (dept) => {
    setDeptCd(dept);
    setYear(currentYear);
    setMon(currentMonth);
  };
  const handleButtonClick2 = (year) => {
    setYear(year);
    if (year !== String(currentYear)) {
        setMon('12');
    } else {
        setMon(currentMonth);
    }
  };

  const handleButtonClick3 = (mon) => {
    if (year === String(currentYear) && mon > currentMonth) {
      showToast(`${currentMonth}월 이후의 시점은 조회할 수 없습니다.`)
    } else {
      setMon(mon);
    }
  };

  const handleButtonClick4 = (empNo, empNm, year, mon, workDay, pjtNo) => {
    setEmpNo2(empNo);
    setEmpNm2(empNm);
    setPjtNo(pjtNo);
    setWorkDay2(workDay);
    setMon2(mon);
    setYear2(year);
    setIsVisible2(true);
  };

  const handleButtonClick5 = (pjtNo) => {
    setPjtNo(pjtNo);
    setIsVisible(true);
  };

  // 배정/투입 공수 헤더
  const renderHeader = (txt) => {
    return (
      <>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>No.</Table.HeaderCell>
            <Table.HeaderCell>고객사</Table.HeaderCell>
            <Table.HeaderCell>프로젝트명</Table.HeaderCell>
            <Table.HeaderCell>계약기간</Table.HeaderCell>
            {monthColumns.map((month, index) => (
              <Table.HeaderCell key={index}>{month}</Table.HeaderCell>
            ))}
            <Table.HeaderCell>{txt}(Year)</Table.HeaderCell>
            <Table.HeaderCell>배정(Total)</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </>
    )
  }

  // 조직 현황 헤더
  const renderHeader2 = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell rowSpan={2}>
            &nbsp;
          </Table.HeaderCell>
          {monthColumns.map((month, index) => (
            <Table.HeaderCell key={index} rowSpan={2}>{month}</Table.HeaderCell>
          ))}
          <Table.HeaderCell colSpan={3}>
            평균
          </Table.HeaderCell>
          <Table.HeaderCell colSpan={3}>
            합계
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>상반기</Table.HeaderCell>
          <Table.HeaderCell>하반기</Table.HeaderCell>
          <Table.HeaderCell>연간</Table.HeaderCell>
          <Table.HeaderCell>상반기</Table.HeaderCell>
          <Table.HeaderCell>하반기</Table.HeaderCell>
          <Table.HeaderCell>연간</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  }

  // 배정/투입 공수 본문
  const renderProjects = (results, stat_data, type) => {
    if (!Array.isArray(results) || results.length === 0 || typeof stat_data !== 'object') {
      return (
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="18">조회할 수 있는 데이터가 없습니다</Table.Cell>
          </Table.Row>
        </Table.Body>
      );
    }

    const sumPjt = Object.values(stat_data).map(item => {
      const sum = item.sum;
      return typeof sum === 'number' ? sum.toFixed(2) : sum;
    });
    // pjt_no가 같고 chrg_busi_cd가 다른 경우에만 한 번만 합산
    const sumPjtTotal = Object.values(results.reduce((acc, project) => {
      const existingProject = acc[project.pjt_no];

      if (!existingProject) {
        // 새로운 프로젝트 추가
        acc[project.pjt_no] = { ...project };

      } else if (existingProject.chrg_busi_cd !== project.chrg_busi_cd) {
        // chrg_busi_cd가 다른 경우, 한 번만 합산
        if (!existingProject.chrg_busi_cd_combined) {
          existingProject.chrg_busi_cd_combined = 'combined'; // 임의의 값으로 설정하여 두 번째 합산을 방지
          existingProject.busi_mm += project.busi_mm;
        }
      }

      return acc;
    }, {}));

    const projectsWithAllMonths = results.map(project => {
      return monthColumns.map(month => ({
        ...project,
        sch_mm: month.slice(0, 2) === project.sch_month ? project.sch_mm : 0,
      }));
    });

    const uniqueProjects = Array.from(new Set(results.map(project => project.pjt_no)))
      .map(pjt_no => {
        const projectsWithSamePjtNo = projectsWithAllMonths.filter(project => project[0].pjt_no === pjt_no);
        return projectsWithSamePjtNo.reduce((mergedProject, currentProject) =>
          mergedProject.map((monthData, index) => ({
            ...monthData,
            sch_mm: +(monthData.sch_mm + currentProject[index].sch_mm),
          }))
        );
      });

    return (
      <Table.Body>
        {uniqueProjects.map((project, index) => (
          <Table.Row key={index}>
            <Table.Cell>{index + 1}</Table.Cell>
            <Table.Cell>
              <a
                href={
                  type === 1 
                    ? `https://hrms.hivelab.co.kr/project/detail?pjtNo=${project[0].pjt_no}`
                    : `https://hrms.hivelab.co.kr/resource/pjt/detail?pjtNo=${project[0].pjt_no}`
                }
                target='_blank'
                rel='noreferrer'
              >
                {project[0].clt_nm}
              </a>
            </Table.Cell>
            <Table.Cell>
              <button
                type="button"
                className="btn_type v1"
                onClick={() => handleButtonClick5(project[0].pjt_no)}
              >
                <span className='txt' title={project[0].pjt_nm}>
                  {project[0].pjt_nm}
                </span>
              </button>
            </Table.Cell>
            <Table.Cell>{project[0].pjt_date_range}</Table.Cell>
            {project.map((monthData, monthIndex) => (
              <Table.Cell key={monthIndex}>
                {monthData.sch_mm === 0 || monthData.sch_mm == null ? '' : monthData.sch_mm.toFixed(2)}
              </Table.Cell>
            ))}
            <Table.Cell>{sumPjt[index]}</Table.Cell>
            <Table.Cell>{(sumPjtTotal[index].busi_mm == null ? 0 : sumPjtTotal[index].busi_mm).toFixed(2)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    );
  };

  // 조직 현황 푸터
  const renderStat = (results, stat_monthly, keyword) => {
    if (!results || !stat_monthly) {
      return null; // 또는 에러 처리에 적절한 메시지를 반환하거나 표시
    }
  
    // 월별 합계 값을 배열로 가져옴
    let sumMonthly = Object.values(stat_monthly).map(item => item.sum);
  
    // 처음 3개 항목을 맨 뒤로 옮김
    sumMonthly = [...sumMonthly.slice(3), ...sumMonthly.slice(0, 3)];
  
    if (!Array.isArray(sumMonthly) || sumMonthly.length === 0) {
      return null; // 또는 에러 처리에 적절한 메시지를 반환하거나 표시
    }
  
    // "배정(Year) 합계" 계산
    const sumYearly = sumMonthly.reduce((acc, value) => acc + value, 0);
  
    // pjt_no가 같고 chrg_busi_cd가 다른 경우에만 한 번만 합산
    const sumPjtTotal = Object.values(results.reduce((acc, project) => {
      const existingProject = acc[project.pjt_no];
  
      if (!existingProject) {
        // 새로운 프로젝트 추가
        acc[project.pjt_no] = { ...project };
      } else if (existingProject.chrg_busi_cd !== project.chrg_busi_cd) {
        // chrg_busi_cd가 다른 경우, 한 번만 합산
        if (!existingProject.chrg_busi_cd_combined) {
          existingProject.chrg_busi_cd_combined = 'combined'; // 임의의 값으로 설정하여 두 번째 합산을 방지
          existingProject.busi_mm += project.busi_mm;
        }
      }
  
      return acc;
    }, {}));
  
    const sumBusiMmTotal = sumPjtTotal.reduce((acc, project) => acc + project.busi_mm, 0);
  
    return (
      <>
        {!keyword && (
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan='4'>합계</Table.Cell>
              {/* 각 월별 합계 표시 */}
              {sumMonthly.map((total, index) => (
                <Table.Cell key={index}>{total.toFixed(2)}</Table.Cell>
              ))}
              {/* "배정(Year) 합계" 표시 */}
              <Table.Cell>{sumYearly.toFixed(2)}</Table.Cell>
              {/* "배정(Total) 합계" 표시 */}
              <Table.Cell>{sumBusiMmTotal.toFixed(2)}</Table.Cell>
            </Table.Row>
          </Table.Footer>
        )}
      </>
    );
  };
  

  // 배정 공수 데이터 배경색 적용
  const getAssignRatioClass = (val) => {
    let cssClass = '';
    if (val >= 90) {
      cssClass = 'idx_high';
    } else if (val >= 50 && val < 90) {
      cssClass = 'idx_mid';
    } else if (val > 0 && val < 50) {
      cssClass = 'idx_low';
    }
    return cssClass;
  };

  const rtnMM = (data, wd) => {
    return (data / 8 / wd).toFixed(2)
  }

  const rtnRatio = (arr1, arr2, arr3) => {
    if (arr1 && arr3) {
      return (rtnMM(arr1, arr2) / arr3 * 100).toFixed(1) + '%'
    } else {
      return ''
    }
  }

  const rtnRatio2 = (arr) => {
    return (arr * 100).toFixed(0)
  }

  const [events, setEvents] = useState([]);

  function addHour(timeStr) {
    if (!timeStr) {
      return null;
    }
    var timeParts = timeStr.split(':');
    var hours = parseInt(timeParts[0], 10);
    var minutes = parseInt(timeParts[1], 10);
    var seconds = parseInt(timeParts[2], 10);

    // 한 시간 추가
    hours = (hours + 2) % 24; // 24시간 넘어가면 0으로 초기화

    // 시간, 분, 초를 두 자리 문자열로 만듬
    var hoursStr = (hours < 10 ? '0' : '') + hours;
    var minutesStr = (minutes < 10 ? '0' : '') + minutes;
    var secondsStr = (seconds < 10 ? '0' : '') + seconds;

    // 새로운 시간 문자열 반환
    return hoursStr + ':' + minutesStr + ':' + secondsStr;
  }

  useEffect(() => {
    if (deptMember) {
      let allEvents = [];
  
      const createEventDate = (dateStr, timeStr) => {
        const date = new Date(dateStr.substring(0, 10));
        if (isNaN(date)) return null;
        date.setDate(date.getDate() + 1);
        return date.toISOString().substring(0, 10) + 'T' + timeStr;
      };
  
      if (deptMember && deptMember.vctn) {
        const vctnEvents = deptMember.vctn.map(item => {
          if (item.vctn_use_days === 0.5 && item.bgn_am_pm === 'A') {
            item.bgn_time = '10:00:00';
            item.end_time = '15:00:00';
            item.eltr_appv_grp_nm = '반차휴가';
          } else if (item.vctn_use_days === 0.5 && item.bgn_am_pm === 'P') {
            item.bgn_time = '15:00:00';
            item.end_time = '19:00:00';
            item.eltr_appv_grp_nm = '반차휴가';
          } else if (item.vctn_use_days === null) {
            item.end_time = addHour(item.bgn_time);
          } else {
            item.bgn_time = '10:00:00';
            item.end_time = '19:00:00';
          }
  
          const start = createEventDate(item.bgn_dt, item.bgn_time);
          const end = createEventDate(item.end_dt, item.end_time);
  
          let eltr_appv_grp_nm = item.eltr_appv_grp_nm;
          if (eltr_appv_grp_nm.includes("사망")) {
            eltr_appv_grp_nm = "弔事";
          }

          return start && end ? {
            title: `${item.emp_nm} (${eltr_appv_grp_nm})`,
            start,
            end
          } : null;
        }).filter(event => event);
  
        allEvents = allEvents.concat(vctnEvents);
      }
      
      const milestones = [
        { title: '입사', start: 0, end: 0 },
        { title: '중간 면담', start: null, end: null },
        { title: '수습 평가', start: null, end: null },
        { title: '수습 면담', start: null, end: null },
        { title: '수습 종료', start: null, end: null }
      ];
  
      const colors = ['#3298B9', '#5BBF7E', '#758E4B', '#E1A1A1'];
  
      if (deptStatData && deptStatData.deptMembers) {
        const milestoneEvents = deptStatData.deptMembers.flatMap(item => {
          item.prbt_bgn_dt = item.prbt_bgn_dt || '10:00:00';
          item.prbt_end_dt = item.prbt_end_dt || '19:00:00';
  
          const start = createEventDate(item.prbt_bgn_dt, item.prbt_bgn_dt.substring(11, 22));
  
          return milestones.map((milestone, index) => {
            let startMilestoneDate = new Date(start);
            let endMilestoneDate = new Date(start);
  
            if (milestone.title === '입사') {
              startMilestoneDate.setDate(startMilestoneDate.getDate() + milestone.start);
              endMilestoneDate.setDate(endMilestoneDate.getDate() + milestone.end);
            } else if (milestone.title === '중간 면담') {
              startMilestoneDate.setMonth(startMilestoneDate.getMonth() + 1);
              endMilestoneDate.setMonth(endMilestoneDate.getMonth() + 2);
            } else if (milestone.title === '수습 평가') {
              startMilestoneDate.setMonth(startMilestoneDate.getMonth() + 2);
              endMilestoneDate.setMonth(endMilestoneDate.getMonth() + 3);
              endMilestoneDate.setDate(endMilestoneDate.getDate() - 14);
            } else if (milestone.title === '수습 면담') {
              startMilestoneDate.setMonth(startMilestoneDate.getMonth() + 3);
              startMilestoneDate.setDate(startMilestoneDate.getDate() - 15);
              endMilestoneDate.setMonth(endMilestoneDate.getMonth() + 3);
              endMilestoneDate.setDate(endMilestoneDate.getDate() - 8);
            } else if (milestone.title === '수습 종료') {
              startMilestoneDate.setMonth(startMilestoneDate.getMonth() + 3);
              startMilestoneDate.setDate(startMilestoneDate.getDate() - 1);
              endMilestoneDate.setMonth(endMilestoneDate.getMonth() + 3);
              endMilestoneDate.setDate(endMilestoneDate.getDate() - 1);
            }
  
            startMilestoneDate.setHours(0, 0, 0, 0);
            endMilestoneDate.setHours(23, 59, 59, 999);
  
            return {
              title: `${item.emp_nm} (${milestone.title})`,
              start: startMilestoneDate.toISOString(),
              end: endMilestoneDate.toISOString(),
              color: colors[index % colors.length],
              allDay: true
            };
          }).filter(event => event);
        });
  
        allEvents = allEvents.concat(milestoneEvents);
      }
  
      setEvents(allEvents);
    }
  }, [deptMember, deptStatData]);

  const classCtrl = (ratio) => {
    if (Number(ratio) < 100) {
      return 'p' + Number(ratio).toFixed(0);
    } else if (Number(ratio) > 100) {
      return 'p100';
    }
  };

  const classCtrl2 = (ratio) => {
    if (ratio >= 90 && ratio < 100) {
      return 'caution';
    } else if (ratio > 100) {
      return 'error';
    } else {
      return false;
    }
  };

  const [totalData, setTotalData] = useState({});
  const [totalData2, setTotalData2] = useState({});

  useEffect(() => {
    if (pjtStatResult && pjtStatResult.results3.length > 0) {
      const total = pjtStatResult.results3.reduce(
        (acc, curr) => {
          return {
            busi_mm: acc.busi_mm + curr.busi_mm,
            use_mm: acc.use_mm + curr.use_mm,
            left_mm: acc.left_mm + curr.left_mm,
          };
        },
        {
          busi_mm: 0,
          use_mm: 0,
          left_mm: 0,
        }
      );
      total.use_ratio = total.use_mm / total.busi_mm;
      setTotalData(total);
    }

    if (pjtStatResult && pjtStatResult.results.length > 0) {
      const total = pjtStatResult.results.reduce(
        (acc, curr) => {
          return {
            busi_mm: acc.busi_mm + curr.busi_mm,
            use_mm: acc.use_mm + curr.use_mm,
            left_mm: acc.left_mm + curr.left_mm,
          };
        },
        {
          busi_mm: 0,
          use_mm: 0,
          left_mm: 0,
        }
      );
      total.use_ratio = total.use_mm / total.busi_mm;
      setTotalData2(total);
    }
  }, [pjtStatResult]);

  // 새로운 객체를 생성하여 각 chrg_busi_cd 값을 키로 하는 총합을 저장
  const summedResults = {};
  pjtStatResult && pjtStatResult.results4.forEach(item => {
    const { chrg_busi_cd, tot_rs_use_time } = item;
    if (!summedResults[chrg_busi_cd]) {
      summedResults[chrg_busi_cd] = tot_rs_use_time;
    } else {
      summedResults[chrg_busi_cd] += tot_rs_use_time;
    }
  });

  // 결과를 새로운 필드로 추가하여 pjtStatResult.results3의 각 항목에 할당
  pjtStatResult && pjtStatResult.results4.forEach(item => {
    const { chrg_busi_cd } = item;
    item.sum_tot_rs_use_time = summedResults[chrg_busi_cd];
  });

  let chrgArr = []
  pjtStatResult && pjtStatResult.results4.forEach(item => {
    chrgArr.push(item.chrg_busi_cd)
  });

  function rowSpan(inputArray) {
    // 각 항목의 출현 횟수 계산
    const countOccurrences = inputArray.reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1;
      return acc;
    }, {});

    // 처음 등장하는 항목에 대해 출현 횟수를, 이후 등장에는 0을 배열에 추가
    const newArr = [];
    const added = new Set(); // 이미 출현 횟수를 추가한 항목을 추적하기 위한 집합

    inputArray.forEach(item => {
      if (!added.has(item)) {
        newArr.push(countOccurrences[item]);
        added.add(item);
      } else {
        newArr.push(0);
      }
    });

    return newArr;
  }

  function ProgressBar(item2) {
    // 소수점 이하 두 자리까지 반올림하여 퍼센트 형식으로 변환하는 함수  
    const progressStyle = {
      width: item2 + '%',
      maxWidth: '100%'
    };

    // item < 10일 경우 'success', 그렇지 않으면 'error' 클래스 적용
    const progressClassName = item2 < 100 ? "ui progress small success" : "ui progress small error";

    return (
      <div className={progressClassName} style={{ margin: '10px' }}>
        <div className="bar" style={progressStyle}>
          <div className="progress">{item2}%</div>
        </div>
      </div>
    );
  }

  const filteredAssignResults = useMemo(() =>
    results.filter(item =>
      item.clt_nm.toLowerCase().includes(keyword.toLowerCase()) || item.pjt_nm.toLowerCase().includes(keyword.toLowerCase())
    ), [results, keyword]);

  const filteredUseResults = useMemo(() =>
    results2.filter(item =>
      item.clt_nm.toLowerCase().includes(keyword.toLowerCase()) || item.pjt_nm.toLowerCase().includes(keyword.toLowerCase())
    ), [results2, keyword]);

  // 데이터 그룹화 함수
  const groupDataByMonthAndDuty = (results) => {
    const groupedData = {};
    const allMonths = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
    const years = new Set(results.map(item => item.year));

    // 모든 달 초기화
    years.forEach(year => {
      allMonths.forEach(month => {
        const monthKey = `${year}-${month}`;
        if (!groupedData[monthKey]) {
          groupedData[monthKey] = {};
        }
      });
    });

    // 실제 데이터 그룹화
    results.forEach((item) => {
      const monthKey = `${item.year}-${item.mon}`;
      if (!groupedData[monthKey][item.chrg_busi_nm]) {
        groupedData[monthKey][item.chrg_busi_nm] = 0;
      }
      groupedData[monthKey][item.chrg_busi_nm] += Number(item.use_mm/8/20);
    });

    return groupedData;
  };

  // 그룹화된 데이터를 ApexCharts 형식으로 변환하는 함수
  const prepareChartData = (groupedData) => {
    let categories = Object.keys(groupedData).sort();
    const seriesData = {};
    const allDuties = new Set();

    // 모든 직군 이름 수집
    categories.forEach(monthKey => {
      Object.keys(groupedData[monthKey]).forEach(duty => {
        allDuties.add(duty);
      });
    });

    // 각 직군에 대해 데이터 준비
    allDuties.forEach(duty => {
      seriesData[duty] = categories.map(monthKey => groupedData[monthKey][duty] !== undefined ? groupedData[monthKey][duty] : 0);
    });

    // 모든 직군 데이터가 0인 달을 필터링
    categories = categories.filter((monthKey, index) => {
      return Object.values(seriesData).some(series => series[index] !== 0);
    });

    // 필터링된 카테고리에 맞게 시리즈 데이터도 필터링
    Object.keys(seriesData).forEach(duty => {
      seriesData[duty] = seriesData[duty].filter((value, index) => categories.includes(Object.keys(groupedData).sort()[index]));
    });

    // 해당 월의 데이터가 0인 직군의 데이터를 숨김
    Object.keys(seriesData).forEach(duty => {
      seriesData[duty] = seriesData[duty].map(value => value === 0 ? null : value);
    });

    const filteredSeries = Object.keys(seriesData).map((duty) => ({
      name: duty,
      data: seriesData[duty]
    })).filter(series => series.data.some(value => value !== null)); // 모든 값이 null이면 제외

    return { series: filteredSeries, categories };
  };

  // 그룹화된 데이터와 차트 데이터를 준비 (월별)
  const { series, categories } = useMemo(() => {
    if (pjtStatResult && pjtStatResult.results) {
      const groupedData = groupDataByMonthAndDuty(pjtStatResult.results);
      const preparedData = prepareChartData(groupedData);
      return preparedData;
    } else {
      return { series: [], categories: [] };
    }
  }, [pjtStatResult]);

  // ApexCharts 옵션
  const options = {
    chart: {
      type: 'bar', // 차트 타입을 line으로 변경
      stacked: true, // stack bar로 설정
      height: 350,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        }
      },
      zoom: {
        enabled: false
      },
      selection: {
        enabled: false
      },
      fontFamily: '나눔고딕'
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val !== null ? val.toFixed(2) : '', // null 값은 표시하지 않음
    },
    stroke: {
      curve: 'smooth'
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#4CAF50', '#546E7A', '#FF9800'],
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: '투입 공수 (MM)',
        style: {
          fontSize: '12px', // 폰트 크기 설정
          fontWeight: '600', // 폰트 굵기 설정
          color: '#666' // 폰트 색상 설정 (선택 사항)
        }
      },
      labels: {
        formatter: (value) => value !== null ? value.toFixed(2) : '' // null 값은 표시하지 않음
      }
    },
    tooltip: {
      y: {
        formatter: (val) => (typeof val === 'number' ? `${val.toFixed(2)} MM` : val)
      }
    }
  };


  // 주차별 데이터 그룹화 함수 (duty 기준)
  const groupDataByWeekAndDuty = (results) => {
    const groupedData = {};

    results.forEach((item) => {
      const week = String(item.week).padStart(2, '0'); // 2자리 숫자로 패딩
      const weekKey = `${week}`;
      const duty = item.chrg_busi_nm;
      const use_mm = Number(item.use_mm);

      if (!groupedData[weekKey]) {
        groupedData[weekKey] = {};
      }
      if (!groupedData[weekKey][duty]) {
        groupedData[weekKey][duty] = 0;
      }

      groupedData[weekKey][duty] += use_mm/8/20;
    });

    return groupedData;
  };

  // 주차별 데이터 그룹화 함수 (emp 기준)
  const groupDataByWeekAndEmp = (results) => {
    const groupedData = {};

    results.forEach((item) => {
      const week = String(item.week).padStart(2, '0'); // 2자리 숫자로 패딩
      const weekKey = `${week}`;
      const emp = item.emp_nm;
      const use_mm = Number(item.use_mm);

      if (!groupedData[weekKey]) {
        groupedData[weekKey] = {};
      }
      if (!groupedData[weekKey][emp]) {
        groupedData[weekKey][emp] = 0;
      }

      groupedData[weekKey][emp] += use_mm/8/20;
    });

    return groupedData;
  };

  // 주차별 차트 데이터를 준비하는 함수
  const prepareWeeklyChartData = (groupedData) => {
    let categories = Object.keys(groupedData).sort();
    const seriesData = {};
    const allKeys = new Set();

    categories.forEach(weekKey => {
      Object.keys(groupedData[weekKey]).forEach(key => {
        allKeys.add(key);
      });
    });

    allKeys.forEach(key => {
      seriesData[key] = categories.map(weekKey => groupedData[weekKey][key] !== undefined ? groupedData[weekKey][key] : null);
    });

    const filteredSeries = Object.keys(seriesData).map((key) => ({
      name: key,
      data: seriesData[key]
    })).filter(series => series.data.some(value => value !== null)); // 모든 값이 null이면 제외

    return { series: filteredSeries, categories };
  };

  // 그룹화된 데이터와 차트 데이터를 준비 (주별)
  const weeklyChartData = useMemo(() => {
    if (pjtStatResult && pjtStatResult.results_2) {
      // duty 기준으로 그룹화 및 차트 데이터 준비
      const groupedWeeklyDataByDuty = groupDataByWeekAndDuty(pjtStatResult.results_2);
      const preparedDataByDuty = prepareWeeklyChartData(groupedWeeklyDataByDuty);

      // emp 기준으로 그룹화 및 차트 데이터 준비
      const groupedWeeklyDataByEmp = groupDataByWeekAndEmp(pjtStatResult.results_2);
      const preparedDataByEmp = prepareWeeklyChartData(groupedWeeklyDataByEmp);

      return {
        dutyChartData: preparedDataByDuty,
        empChartData: preparedDataByEmp
      };
    } else {
      return {
        dutyChartData: { series: [], categories: [] },
        empChartData: { series: [], categories: [] }
      };
    }
  }, [pjtStatResult]);


  // weeklyOptions 수정
  const weeklyOptions = {
    chart: {
      type: 'bar', // 차트 타입을 stack bar로 변경
      stacked: true, // stack bar로 설정
      height: 350,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
      fontFamily: '나눔고딕',
    },
    dataLabels: {
      enabled: false,
      formatter: (val) => (val !== null ? val.toFixed(2) : ''), // null 값은 표시하지 않음
    },
    stroke: {
      curve: 'smooth',
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0',
      '#3F51B5', '#03A9F4', '#4CAF50', '#F9CE1D', '#FF9800',
      '#33B2DF', '#546E7A', '#D4526E', '#13D8AA', '#A5978B',
      '#4ECDC4', '#C7F464', '#81D4FA', '#546E7A', '#FD6A6A',
      '#2B908F', '#F9A3A4', '#90EE7E', '#FA4443', '#69D2E7',
      '#449DD1', '#F86624', '#EA3546', '#662E9B', '#C5D86D',
      '#D7263D', '#1B998B', '#2E294E', '#F46036', '#E2C044',
      '#662E9B', '#F86624', '#F9C80E', '#EA3546', '#43BCCD',
      '#5C4742', '#A5978B', '#8D5B4C', '#5A2A27', '#C4BBAF',
      '#A300D6', '#7D02EB', '#5653FE', '#2983FF', '#00B1F2'],
    xaxis: {
      categories: weeklyChartData.dutyChartData.categories, // dutyChartData에서 가져오도록 수정
      title: {
        text: '주차 (Week)',
        offsetX: 0,
        offsetY: -5,
        style: {
          fontSize: '12px', // 폰트 크기 설정
          fontWeight: '600', // 폰트 굵기 설정
          color: '#666', // 폰트 색상 설정 (선택 사항)
        },
      },
    },
    yaxis: {
      title: {
        text: '투입 공수 (MM)',
        style: {
          fontSize: '12px', // 폰트 크기 설정
          fontWeight: '600', // 폰트 굵기 설정
          color: '#666', // 폰트 색상 설정 (선택 사항)
        },
      },
      labels: {
        formatter: (value) => (value !== null ? value.toFixed(2) : ''), // null 값은 표시하지 않음
      },
    },
    tooltip: {
      y: {
        formatter: (val) => (typeof val === 'number' ? `${val.toFixed(2)} MM` : val),
      },
    },
  };

  const renderMonthlyTable = (series, categories) => {
    const sumByCategory = categories.map((_, index) =>
      series.reduce((acc, item) => acc + (item.data[index] || 0), 0)
    );
  
    const totalSumBySeries = series.map(item =>
      item.data.reduce((acc, value) => acc + (value || 0), 0)
    );
  
    const grandTotal = totalSumBySeries.reduce((acc, value) => acc + value, 0);
  
    return (
      <table className="ui celled table v3  fixed">
        <thead>
          <tr>
            <th>주차</th>
            {series.map((item, index) => (
              <th key={index}>{item.name}</th>
            ))}
            <th>합계</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td>합계</td>
            {totalSumBySeries.map((sum, index) => (
              <td key={index}>{sum.toFixed(2)}</td>
            ))}
            <td>{grandTotal.toFixed(2)}</td>
          </tr>
        </tfoot>
        <tbody>
          {categories.map((category, index) => (
            <tr key={index}>
              <td>{category}</td>
              {series.map((item, seriesIndex) => (
                <td key={seriesIndex}>
                  {typeof item.data[index] === 'number'
                    ? item.data[index].toFixed(2)
                    : item.data[index]}
                </td>
              ))}
              <td>{sumByCategory[index].toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderWeeklyDutyTable = (series, categories) => {
    const sumByCategory = categories.map((_, index) =>
      series.reduce((acc, item) => acc + (item.data[index] || 0), 0)
    );

    const totalSumBySeries = series.map(item =>
      item.data.reduce((acc, value) => acc + (value || 0), 0)
    );

    const grandTotal = totalSumBySeries.reduce((acc, value) => acc + value, 0);

    const getWeekRange = (week) => {
      const weekData = pjtStatResult.results_2.find(item => item.week === parseInt(week, 10));
      if (weekData) {
        const startDate = new Date(weekData.year, weekData.mon - 1, weekData.day);
        // 월요일부터 시작하도록 조정
        const dayOffset = (startDate.getDay() + 6) % 7;
        startDate.setDate(startDate.getDate() - dayOffset);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        const options = { month: 'short', day: '2-digit', weekday: 'short' };
        const startStr = startDate.toLocaleDateString('ko-KR', options);
        const endStr = endDate.toLocaleDateString('ko-KR', options);

        return `${startStr} ~ ${endStr}`;
      }
      return '';
    };

    return (
      <table className="ui celled table v3 fixed">
        <thead>
          <tr>
            <th>주차</th>
            {series.map((item, index) => (
              <th key={index}>{item.name}</th>
            ))}
            <th>합계</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td>합계</td>
            {totalSumBySeries.map((sum, index) => (
              <td key={index}>{sum.toFixed(2)}</td>
            ))}
            <td>{grandTotal.toFixed(2)}</td>
          </tr>
        </tfoot>
        <tbody>
          {categories.map((category, index) => (
            <tr key={index}>
              <td title={getWeekRange(category)}>{category}</td>
              {series.map((item, seriesIndex) => (
                <td key={seriesIndex}>
                  {typeof item.data[index] === 'number'
                    ? item.data[index].toFixed(2)
                    : item.data[index]}
                </td>
              ))}
              <td>{sumByCategory[index].toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderWeeklyEmpTable = (series, categories) => {
    const sumByCategory = categories.map((_, index) =>
      series.reduce((acc, item) => acc + (item.data[index] || 0), 0)
    );

    const totalSumBySeries = series.map(item =>
      item.data.reduce((acc, value) => acc + (value || 0), 0)
    );

    const grandTotal = totalSumBySeries.reduce((acc, value) => acc + value, 0);

    const getWeekRange = (week) => {
      const weekData = pjtStatResult.results_2.find(item => item.week === parseInt(week, 10));
      if (weekData) {
        const startDate = new Date(weekData.year, weekData.mon - 1, weekData.day);
        // 월요일부터 시작하도록 조정
        const dayOffset = (startDate.getDay() + 6) % 7;
        startDate.setDate(startDate.getDate() - dayOffset);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        const options = { month: 'short', day: '2-digit', weekday: 'short' };
        const startStr = startDate.toLocaleDateString('ko-KR', options);
        const endStr = endDate.toLocaleDateString('ko-KR', options);

        return `${startStr} ~ ${endStr}`;
      }
      return '';
    };

    return (
      <table className="ui celled table v3 fixed">
        <thead>
          <tr>
            <th>주차</th>
            {series.map((item, index) => (
              <th key={index}>{item.name}</th>
            ))}
            <th>합계</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td>합계</td>
            {totalSumBySeries.map((sum, index) => (
              <td key={index}>{sum.toFixed(2)}</td>
            ))}
            <td>{grandTotal.toFixed(2)}</td>
          </tr>
        </tfoot>
        <tbody>
          {categories.map((category, index) => (
            <tr key={index}>
              <td title={getWeekRange(category)}>{category}</td>
              {series.map((item, seriesIndex) => (
                <td key={seriesIndex}>
                  {typeof item.data[index] === 'number'
                    ? item.data[index].toFixed(2)
                    : item.data[index]}
                </td>
              ))}
              <td>{sumByCategory[index].toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  

  if (!login) {
    return <ToastContainer />;
  } else {
    return (
      <div>
        <ul className="ui vertical menu">
          {dept_menu && dept_menu.map((item, index) =>
            <DepartmentItem key={index} dept={item} deptCd={deptCd} handleButtonClick={handleButtonClick} />
          )}
        </ul>
        {/* 연도 탭 */}
        <ul className="ui menu v1">
          {(deptYear && deptYear.length > 0) ? (
            deptYear.map((item, index) => (
              <li key={index}>
                <button
                  type="button"
                  key={index}
                  className={`item ${String(year) === item || year === item ? 'active' : ''}`}
                  onClick={() => handleButtonClick2(item)}
                >
                  {item}
                </button>
              </li>
            ))
          ) : (
            <li>
              <button
                type="button"
                className={`item active`}
                onClick={() => handleButtonClick2(currentYear)}
              >
                {currentYear}
              </button>
            </li>
          )}
        </ul>
        {typeof results3 === 'object' && emp_Sum > 0 && (
          <div className='bx'>
            <div className='bx2'>

              <div>
                <h2>리소스 현황</h2>
                <div className='bx'>
                  <Chart
                    seriesData={seriesData}
                    seriesData2={seriesData2}
                  />
                </div>
              </div>
            </div>
            <div className='bx3'>
              <h2>조직원 현황</h2>
              <div className="ui buttons mini">
                {monthColumns && monthColumns.length && monthColumns.map((item, index) => (

                  <button
                    type="button"
                    key={index}
                    className={`item ui button ${String(mon) === item.substring(0, 2) ? 'active' : ''}`}
                    onClick={() => handleButtonClick3(item.substring(0, 2))}
                  >
                    {item}
                  </button>

                ))}
              </div>
              <DeptMemberStat
                deptMember={deptMember}
                filteredDeptMembers={filteredDeptMembers}
                setEmpNo={setEmpNo}
                setEmpNm={setEmpNm}
                setDutyNm={setDutyNm}
                deptStat={deptStat}
              />
            </div>
          </div>
        )}
        <br />
        {empNm !== null && memberDetailProcessed.updatedUniqueTaskInfo.length > 0 && (
          <div>
            <h2>[{mon}월] {empNm} {dutyNm}</h2>
            <DeptMemberStatDetail
              memberDetail={memberDetail}
              totalSum={memberDetailProcessed.totalSum}
              rtnMM={rtnMM}
              workDay={memberDetailProcessed.workDay}
              assignSum={memberDetailProcessed.assignSum}
              sumData={memberDetailProcessed.sumData}
              updatedUniqueTaskInfo={memberDetailProcessed.updatedUniqueTaskInfo}
              rowSpans={memberDetailProcessed.rowSpans}
              sumByProjectArray={memberDetailProcessed.sumByProjectArray}
              rtnRatio={rtnRatio}
              sumsArray={memberDetailProcessed.sumsArray}
              weekendDays={memberDetailProcessed.weekendDays}
              handleButtonClick5={handleButtonClick5}
            />
          </div>
        )}

        <div className='bx4'>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView='dayGridMonth'
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            locale={koLocale}
            height={541}
            events={events}
          />
        </div>

        {typeof results3 === 'object' && emp_Sum > 0 && (
          <div>
            <h2>조직 현황</h2>
            <Table celled className="tbl_type v1 unstackable">
              {renderHeader2()}
              {renderDeptStat(results3, stat_data3)}
            </Table>
          </div>
        )}

        <SearchBar keyword={keyword} setKeyword={setKeyword} />

        <h2>배정 공수</h2>
        <Table celled className="tbl_type v3 unstackable">
          {renderHeader('배정')}
          {renderStat(filteredAssignResults, stat_monthly, keyword)}
          {renderProjects(filteredAssignResults, stat_data, 1)}
        </Table>

        <h2>투입 공수</h2>
        <Table celled className="tbl_type v3 unstackable">
          {renderHeader('투입')}
          {renderStat(filteredUseResults, stat_monthly2, keyword)}
          {renderProjects(filteredUseResults, stat_data2, 2)}
        </Table>
        {isVisible && (
          <>
            <div className={`ui modals dimmer page ${isVisible ? 'active' : ''}`}>
              <div className={`ui longer modal ${isFullscreen ? 'fullscreen' : ''} ${isVisible ? 'active' : ''}`}>
                <i
                  className={`${isFullscreen ? 'compress' : 'expand'} icon large btn_type2`}
                  onClick={() => setIsFullscreen(!isFullscreen)}
                  style={{ cursor: 'pointer' }}
                ></i>

                <i className="close icon" onClick={() => { setIsVisible(false); setActiveTab('monthly'); setIsFullscreen('') }}></i>


                <i
                  className="save outline icon large btn_print"
                  onClick={() => {
                    const period = pjtStatResult.pjtInfo[0].period;
                    const buttonText = period ? `${pjtStatResult.pjtInfo[0].pjt_nm} (${period})` : pjtStatResult.pjtInfo[0].pjt_nm;
                    onButtonClick(buttonText);
                  }}
                  style={{ cursor: 'pointer', marginLeft: '10px' }} // 아이콘 간 간격을 위해 스타일 추가
                ></i>

                <div className="header">
                    {pjtStatResult && pjtStatResult.pjtInfo[0] && (
                        <>
                            {pjtStatResult.pjtInfo[0].pjt_nm}
                            &nbsp;
                            {pjtStatResult.pjtInfo[0].period && `(${pjtStatResult.pjtInfo[0].period})`}
                        </>
                    )}
                </div>

                <div
                  className={`content ${isPrint === false ? 'scrolling' : ''}`}
                  ref={ref}
                >
                  {pjtStatResult && pjtStatResult.results3 && pjtStatResult.results3.length > 0 ? (
                    <>
                      <h2>직군별 리소스 현황 (전체)</h2>
                      <table className="ui celled table fixed">
                        <caption className="blind">프로젝트 상세 현황</caption>
                        <thead>
                          <tr>
                            <th scope="col">수행 직무</th>
                            <th scope="col">배정 조직</th>
                            <th scope="col">계약 공수</th>
                            <th scope="col">투입 공수</th>
                            <th scope="col">남은 공수</th>
                            <th scope="col">투입률</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <td>합계</td>
                            <td>-</td>
                            <td>{totalData.busi_mm && totalData.busi_mm.toFixed(2)}</td>
                            <td>{totalData.use_mm && (totalData.use_mm.toFixed(2)/8/20).toFixed(2)}</td>
                            <td>{totalData.use_mm && (totalData.busi_mm-(totalData.use_mm/8/20)).toFixed(2)}</td>
                            <td>{isFinite(totalData.use_mm) ? ((totalData.use_mm/8/20).toFixed(2)/totalData.busi_mm * 100).toFixed(1) + '%' : '-'}</td>
                          </tr>
                        </tfoot>
                        <tbody>
                          {pjtStatResult && pjtStatResult.results3.map((item, key) => (
                            <tr key={key}>
                              <td>{item.chrg_busi_nm}</td>
                              <td className="tc">{item.dept_nm}</td>
                              <td className="tc">{item.busi_mm}</td>
                              <td className='tc'>{item.use_mm && (item.use_mm.toFixed(2)/8/20).toFixed(2)}</td>
                              <td>{(item.busi_mm-(item.use_mm/8/20)).toFixed(2)}</td>
                              <td>
                                <div className="bx5 v2">
                                  <div className={`c100 v1 center ${classCtrl(((item.use_mm/8/20).toFixed(2)/item.busi_mm * 100).toFixed(2))}`}>
                                    {((item.use_mm/8/20).toFixed(2)/item.busi_mm * 100).toFixed(2) > 0 ? (
                                      <span>{((item.use_mm/8/20).toFixed(2)/item.busi_mm * 100).toFixed(2) > 0 ? ((item.use_mm/8/20)/item.busi_mm * 100).toFixed(1) : ''}%</span>
                                    ) : (
                                      <span></span>
                                    )}
                                    <div className="slice">
                                      <div className={`bar ${classCtrl2(((item.use_mm/8/20).toFixed(2)/item.busi_mm * 100).toFixed(2))}`}></div>
                                      <div className={`fill ${classCtrl2(((item.use_mm/8/20).toFixed(2)/item.busi_mm * 100).toFixed(2))}`}></div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <div className="ui pointing menu">
                         <button type="button" className={`item btn_type ${activeTab === 'monthly' ? 'active' : ''}`} onClick={() => setActiveTab('monthly')}>
                          직군별 리소스 현황 (월별)
                        </button>
                         <button type="button" className={`item btn_type ${activeTab === 'weeklyDuty' ? 'active' : ''}`} onClick={() => setActiveTab('weeklyDuty')}>
                          직군별 리소스 현황 (주별)
                        </button>
                         <button type="button" className={`item btn_type ${activeTab === 'weeklyEmp' ? 'active' : ''}`} onClick={() => setActiveTab('weeklyEmp')}>
                          작업자별 리소스 현황 (주별)
                        </button>
                      </div>

                      <div className="ui segment">
                        {activeTab === 'monthly' && (
                          <>
                            <h2>직군별 리소스 현황 (월별)</h2>
                            <ApexCharts options={options} series={series} type="bar" height={500} />
                            {renderMonthlyTable(series, categories)}
                          </>
                        )}
                        {activeTab === 'weeklyDuty' && (
                          <>
                            <h2>직군별 리소스 현황 (주별)</h2>
                            <ApexCharts options={weeklyOptions} series={weeklyChartData.dutyChartData.series} type="bar" height={500} />
                            {renderWeeklyDutyTable(weeklyChartData.dutyChartData.series, weeklyChartData.dutyChartData.categories)}
                          </>
                        )}
                        {activeTab === 'weeklyEmp' && (
                          <>
                            <h2>작업자별 리소스 현황 (주별)</h2>
                            <ApexCharts options={weeklyOptions} series={weeklyChartData.empChartData.series} type="bar" height={500} />
                            {renderWeeklyEmpTable(weeklyChartData.empChartData.series, weeklyChartData.empChartData.categories)}
                          </>
                        )}
                      </div>

                      <h2>작업자별 리소스 현황 (전체)</h2>
                      <table className="ui celled table fixed">
                        <caption className="blind"></caption>
                        <thead>
                          <tr>
                            <th scope="col">담당자</th>
                            <th scope="col">담당 업무</th>
                            <th scope="col">투입 공수(MM)</th>
                            <th scope="col">직군 합계(MM)</th>
                          </tr>
                        </thead>
                        <tfoot>
                          <tr>
                            <td>합계</td>
                            <td>-</td>
                            <td>{totalData2.use_mm && ((totalData.use_mm)/8/20).toFixed(2)}</td>
                            <td>{totalData2.use_mm && ((totalData.use_mm)/8/20).toFixed(2)}</td>
                          </tr>
                        </tfoot>
                        <tbody>
                          {pjtStatResult && pjtStatResult.results4.map((item, key) => (
                            <tr key={key}>
                              <td className='tc'>{item.emp_nm}</td>
                              {
                                rowSpan(chrgArr)[key] !== 0 && (
                                  <td rowSpan={rowSpan(chrgArr)[key]} className='tc'>{item.chrg_busi_nm}</td>
                                )
                              }
                              <td className='tc'>{(item.tot_rs_use_time/8/20).toFixed(2)}</td>
                              {
                                rowSpan(chrgArr)[key] !== 0 && (
                                  <td rowSpan={rowSpan(chrgArr)[key]} className='tc'>{(item.sum_tot_rs_use_time/8/20).toFixed(2)}</td>
                                )
                              }
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      <h2>작업자별 리소스 현황 (월별)</h2>
                      {pjtStatResult && pjtStatResult.period.map((item, key) => (
                        <div key={key} className="bx7">
                          <h3>{item.year}년 {item.mon}월</h3>

                          <table className="ui celled table fixed">
                            <caption className="blind">작업자별 리소스 현황 상세 (월별)</caption>
                            <thead>
                              <tr>
                                <th scope="col">담당자</th>
                                <th scope="col">담당 업무</th>
                                <th scope="col">투입 공수(MM)</th>
                                <th scope="col">투입률</th>
                              </tr>
                            </thead>
                            <tfoot>
                              <tr>
                                <td>합계</td>
                                <td>-</td>
                                <td>
                                  {pjtStatResult && pjtStatResult.results
                                    .filter(resultItem => resultItem.year === item.year && resultItem.mon === item.mon)
                                    .reduce((acc, filteredItem) => acc + Number(filteredItem.use_mm/8/20), 0)
                                    .toFixed(2)
                                  }
                                </td>
                                <td>-</td>
                              </tr>
                            </tfoot>
                            <tbody>

                              {pjtStatResult && pjtStatResult.results
                                .filter(resultItem => resultItem.year === item.year && resultItem.mon === item.mon)
                                .map((filteredItem, filteredKey) => (
                                  <tr key={filteredKey}>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn_type"
                                        onClick={() => handleButtonClick4(filteredItem.ins_no, filteredItem.emp_nm, item.year, item.mon, filteredItem.day_count, filteredItem.pjt_no)}
                                      >
                                        {filteredItem.emp_nm}
                                      </button>
                                    </td>
                                    <td className="tc">{filteredItem.chrg_busi_nm}</td>
                                    <td className="tc">{(filteredItem.use_mm/8/20).toFixed(2)}</td>
                                    <td>
                                      {ProgressBar(rtnRatio2(filteredItem.use_mm/8/20))}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </>
                  ) : (
                    <table className="ui celled table">
                      <thead>
                        <tr>
                          <th scope="col">담당자</th>
                          <th scope="col">담당 업무</th>
                          <th scope="col">투입 공수(MM)</th>
                          <th scope="col">직군 합계(MM)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={4}>조회할 수 있는 데이터가 없습니다</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="actions">
                  <button
                    type="button"
                    className="ui button btn_type v2"
                    onClick={() => {
                      setIsVisible(false);
                      setActiveTab('monthly');
                      setIsFullscreen('');
                    }}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
            {isVisible2 && (
              <div className="ui modals dimmer page active v1">
                <div className="ui longer modal active">
                  <i
                    className="close icon"
                    onClick={() => setIsVisible2(false)}
                  ></i>
                  {/* <button type="button" className="btn_print">Export (PNG)</button> */}
                  <h2>[{year2}년 {mon2}월] {empNm2}</h2>
                  <DeptMemberStatDetail2
                    memberDetail={memberDetail2}
                    totalSum={memberDetail2Processed.totalSum}
                    rtnMM={rtnMM}
                    workDay={workDay2}
                    assignSum={memberDetail2Processed.assignSum}
                    sumData={memberDetail2Processed.sumData}
                    updatedUniqueTaskInfo={memberDetail2Processed.updatedUniqueTaskInfo}
                    rowSpans={memberDetail2Processed.rowSpans}
                    sumByProjectArray={memberDetail2Processed.sumByProjectArray}
                    rtnRatio={rtnRatio}
                    sumsArray={memberDetail2Processed.sumsArray}
                    weekendDays={memberDetail2Processed.weekendDays}
                    pjtNo={pjtNo}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {isVisible3 && (
          <div className='bx'>
            <div className='bx2'>
              <div>
                <div className='bx'>
                  <Chart2
                    seriesData3={seriesData3}
                    seriesData4={seriesData4}
                    seriesData5={seriesData5}
                    seriesData6={seriesData6}
                    labels3={filteredResults}
                    labels4={summarizedResults}
                    labels5={deptAssignAllResult}
                    labels6={deptAssignSingleResult}
                    deptNm={deptNm}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
};

export default Resource;
